import React, { Suspense, lazy, useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import PopUp from './util/PopUp';

const App = lazy(() => import('./HomePage/App'));
const ServicesPage = lazy(() => import('./ServicesPage'));
const MissionsPage = lazy(() => import('./MissionsPage'));
const MovingPage = lazy(() => import('./MovingPage'));
const Contact = lazy(() => import('./Contact'));

const isDev = process.env.NODE_ENV === 'development';

export default () => {
  const [toast, showToast] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('emailOfferDisplayed') === 'true') {
      return;
    }
    let timeout = setTimeout(
      () => {
        showToast(true);
      },
      isDev ? 1000 : 40 * 1000
    );
    return () => clearTimeout(timeout);
  }, []);

  const closeToast = () => {
    showToast(false);
  };

  return (
    <>
      <BrowserRouter>
        <Suspense fallback={() => <div>Loading....</div>}>
          <Switch>
            <Route exact path="/" component={(props) => <App {...props} />} />
            <Route
              exact
              path="/services"
              component={(props) => <ServicesPage {...props} />}
            />
            <Route
              exact
              path="/moving"
              component={(props) => <MovingPage {...props} />}
            />
            <Route
              exact
              path="/mission"
              component={(props) => <MissionsPage {...props} />}
            />
            <Route
              exact
              path="/contact"
              component={(props) => <Contact {...props} file="Contact" />}
            />
            <Route
              exact
              path="/happiness-guarantee"
              component={(props) => <Contact {...props} file="Happiness" />}
            />
            <Route
              exact
              path="/terms"
              component={(props) => <Contact {...props} file="Terms" />}
            />
            <Route
              exact
              path="/privacy"
              component={(props) => <Contact {...props} file="Privacy" />}
            />
            <Route
              exact
              path="/cancellation-policy"
              component={(props) => <Contact {...props} file="Cancellation" />}
            />
          </Switch>
        </Suspense>
      </BrowserRouter>
      {toast ? <PopUp show={toast} close={closeToast} /> : null}
    </>
  );
};
