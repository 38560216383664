import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import Routes from './components/Routes';
import { CSSReset, theme, ThemeProvider } from '@chakra-ui/core';
import { createClient, Provider as UrqlProvider } from 'urql';

const client = createClient({
  url: process.env.REACT_APP_GRAPHQL_URL,
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CSSReset />
    <UrqlProvider value={client}>
      <Routes />
    </UrqlProvider>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
